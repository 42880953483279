<template>
  <div class="">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input class="select-width id-code-width" size="medium" v-model="queryForm.searchValue"
          placeholder="姓名/手机号" />

        <!--        v-if="roles.code === 'HOTEL_ADMIN'"-->
        <el-select class="select-width" size="medium" v-btn-permission="'008003002001'"
          v-model="queryForm.isolationPointId" placeholder="隔离点">
          <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
        </el-select>

        <el-date-picker v-model="pickerTime" size="medium" type="daterange" value-format="yyyy-MM-dd"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
          
          <el-select class="select-width" size="medium" style="margin-left:20px;"
          v-model="queryForm.peopleStaute" placeholder="人员状态">
          <el-option v-for="item in peopleStauteList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryInfo" :disabled="loading">查询</el-button>
        <el-button type="primary" size="small" @click="resetQueryInfo" :disabled="loading">重置</el-button>
        <el-button type="primary" v-btn-permission="'008006001006'" size="small" @click="addStaffInfo('add')" :disabled="loading">新增
        </el-button>
        <el-button type="primary" size="small" @click="exportListData" :disabled="loading">导出</el-button>
      </div>
    </div>

    <TableModel :is-show-select="true" :loading="loading" :table-data="staffCheckList" table-size="medium" @handleSelectionChange="handleSelection">
      <el-table-column prop="transportStatus" type="selection" width="55">

      </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="{ row }">
          <el-link type="primary" v-btn-permission="'008003002002'" @click="addStaffInfo('edit', row)">编辑</el-link>
          <el-link type="primary" v-btn-permission="'008003002003'" @click="enterCheckRecord(row)" v-if="row.status == 1">录入</el-link>
          <el-link type="primary" v-btn-permission="'008003002004'" @click="printWorkerDialog(row)" v-if="row.status == 1">打印条码</el-link>
          <el-link type="primary" v-btn-permission="'008003002005'" @click="checkRecord(row.id)" v-if="row.status == 1">历次检测</el-link>
          <!-- v-btn-permission="'008003002006'"  -->
          <el-link v-if="row.status == 0" type="primary" @click="handleStart(row)">启动</el-link>
          <!-- v-btn-permission="'008003002007'"  -->
          <el-link v-if="row.status == 1" type="danger" @click="handleEnd(row)">停用</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="员工姓名" width="100" />
      <el-table-column prop="sex" label="性别" width="80" />
      <el-table-column prop="age" label="年龄" width="80" />
      <el-table-column prop="cardNo" label="身份证号" width="200" />
      <el-table-column prop="linkPhone" label="手机号" width="120" />
      <el-table-column prop="status" label="人员状态">
        <template slot-scope="{ row }">
          <el-button class="statusBtn" v-if="row.status == 1" type="primary" style="width:50px;height:20px;padding:0;font-size: 12px;">启动</el-button>
          <el-button class="statusBtn2" v-if="row.status == 0" type="danger" style="width:50px;height:20px;padding:0;font-size: 12px;">停用</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="isolationPointName" label="隔离点" v-btn-permission="'008003002001'" />
      <el-table-column prop="barCode" label="条码号" width="200" />
      <el-table-column prop="lastCollectTime" label="最近一次核酸检测时间" width="200" />
      <el-table-column prop="lastLabResult" label="最近一次核酸检测结果" width="200">
        <template slot-scope="{ row }">
          <el-tag size="small" type="danger" v-if="row.lastLabResult === '阳性'">阳性</el-tag>
          <el-tag size="small" v-else-if="row.lastLabResult === '阴性'">阴性</el-tag>
          <el-tag size="small" v-else-if="row.lastLabResult === '可疑'">可疑</el-tag>
        </template>
      </el-table-column>
    </TableModel>

    <div class="content_page">
      <div>
            <span>已选中项目&nbsp;&nbsp;&nbsp;{{checkData.length}}&nbsp;&nbsp;&nbsp;批量操作</span>
            <el-button :disabled="loading || !checkData.length" style="margin-left:20px;" type="primary" size="medium" @click="manyLog"
            >批量打印条码</el-button>
      </div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNumber"
        :page-size="pageSize" :total="total" :page-sizes="[10, 20, 40, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>

    <!--新增 / 编辑弹窗-->
    <Dialog ref="diglogRef" :dialog-title="dialogTitle" :is-show-dialog="formDialog" label-width="86px" @closeDialog="closeDialog" @submitForm="submitFormInfo" v-loading="loading">
      <el-form-item label="姓名：">
        <el-input v-model="formInfo.name" size="medium" class="form-width" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="身份证号：">
        <el-input v-model="formInfo.cardNo" size="medium" class="form-width" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input v-model="formInfo.linkPhone" size="medium" maxlength="11" class="form-width" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="状态：">
        <el-radio v-model="formInfo.status" label="1" style="width:100px;">启动</el-radio>
        <el-radio v-model="formInfo.status" label="0" style="width:150px;">停用</el-radio>
      </el-form-item>
    </Dialog>

    <!--历次检测弹窗-->
    <Dialog dialog-title="历次检测记录" :is-show-dialog="checkRecordDialog" label-width="86px" dialog-width="650px" v-loading="loading"
      @closeDialog="closeDialog" @submitForm="closeDialog">
      <el-table :data="checkRecordList" :header-cell-style="{ background: '#ffffff', color: '#909399' }" size="medium"
        :max-height="400" style="width: 100%;">
        <el-table-column prop="collectTime" label="检测时间" />
        <el-table-column prop="samplingMode" label="采样方式" />
        <el-table-column prop="labResult" label="检测结果">
          <template slot-scope="{ row }">
            <el-tag size="small" type="danger" v-if="row.labResult === '阳性'">阳性</el-tag>
            <el-tag size="small" v-else-if="row.labResult === '阴性'">阴性</el-tag>
            <el-tag size="small" v-else-if="row.labResult === ''">暂无</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="barCode" label="条码号" width="220">
          <template slot-scope="{ row }">
            <div v-if="row.todayBarCode == '0'">{{row.barCode}}</div>
            <div @click="handelLog(row)" style="text-decoration:underline;cursor: pointer;" v-if="row.todayBarCode == '1'">
              {{row.barCode}}
            </div>
            <!-- <el-link>有下划线</el-link> -->
          </template>
        </el-table-column>
      </el-table>

      <el-pagination style="margin-top: 12px" @size-change="changeSize" @current-change="changeCurrent"
        :current-page="pageData.pageNumber" :page-size="pageData.pageSize" :total="pageData.total"
        :page-sizes="[10, 20, 40, 50, 100]" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </Dialog>

    <!--录入弹窗-->
    <EnterCheckRecord ref="EnterCheckRecordRef"></EnterCheckRecord>

    <el-dialog title="核酸检测采样方式" :visible.sync="workerDialog" width="30%" v-loading="loading">
      <el-form :model="printWorkerForm">
        <el-alert
            v-if="whetherTodayBarCode == '1'"
            title="今日已生成核酸条码"
            type="warning"
            center
            show-icon
            style="margin:-20px 0 20px 0;">
          </el-alert>
        <el-form-item label="采样方式" :label-width="'120px'">
          <el-select style="width:100%;" class="select-width" size="medium" v-model="printWorkerForm.samplingMode"
            placeholder="请选择核酸检测采样方式">
            <el-option v-for="item in samplingModeList" :key="item.dictSort" :label="item.dictLabel"
              :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="printWorker('1')" :disabled="whetherTodayBarCode != '1'">打印已生成条码</el-button>
        <el-button type="primary" @click="printWorker('0')">重新生成条码</el-button>
      </div>
    </el-dialog>

    <el-dialog title="核酸检测采样方式" :visible.sync="workerDialog2" width="30%"  v-loading="loading">
      <el-form :model="printWorkerForm2">
        <el-form-item label="采样方式" :label-width="'120px'">
          <el-select style="width:100%;" class="select-width" size="medium" v-model="printWorkerForm2.samplingMode"
            placeholder="请选择核酸检测采样方式">
            <el-option v-for="item in samplingModeList2" :key="item.dictSort" :label="item.dictLabel"
              :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="workerDialog2 = false">取消</el-button>
        <el-button type="primary" @click="printWorker2()">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import TableModel from '@/components/TableModel';
import Dialog from '@/components/Dialog';
import EnterCheckRecord from './EnterCheckRecord';
import {
  workerRegList,
  workerRegInfo,
  saveWorkerReg,
  updateWorkerReg,
  printWorkerCode,
  workerCovidList,
  exportWorkerRegListApi,
  changeStatus,
  batchPrintWorkerCovidBarCode
} from '../../api/HotelManagement/roomManagement';
import {getIdNumberInfo, printBarcode, messageBox, importFile, batchPrintBarcode} from '../../utils/utils';
import { isIDCard, isPhone } from '../../utils/validate';
import { mapState } from 'vuex';
import { samplingModeList } from "../../api/QuarantineRegistration/quarantineRegistrationpop";

export default {
  name: "StaffCheck",
  components: { TableModel, Dialog, EnterCheckRecord },
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      roles: JSON.parse(localStorage.getItem("roles")),
      pageNumber: 1,
      pageSize: 20,
      total: 0,
      label:'',
      peopleStauteList:[
        {
          value:'1',
          label:'启动'
        },
        {
          value:'0',
          label:'停用'
        },
      ],
      queryForm: {
        searchValue: "",
        isolationPointId: "",
        peopleStaute:"1"
      },
      pageData: {
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      formInfo: {
        name: "",
        linkPhone: "",
        cardNo: "",
        status:""
      },
      loading: false,
      workerDialog: false,
      workerDialog2: false,
      printWorkerForm: {
        id: '',
        samplingMode: '',
        barCode: '',
        printType:''
      },
      printWorkerForm2: {
        id: '',
        samplingMode: ''
      },
      samplingModeList: [],
      samplingModeList2: [],
      staffCheckList: [],
      pickerTime: [],
      dialogTitle: "",
      formDialog: false,
      checkRecordDialog: false,
      checkRecordList: [],
      isolationPointId: "",
      checkData: [],
      radio:'1',
      whetherTodayBarCode:''
    }
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList
    })
  },

  created() {
    this.getWorkerRegList();
  },

  methods: {
    // 链接打印
    handelLog(row){
      console.log(row);
      this.printWorkerForm.id = row.workerRegId
      this.whetherTodayBarCode= row.whetherTodayBarCode
      this.printWorkerForm.samplingMode = row.samplingMode
      this.printWorkerForm.barCode = row.barCode
      this.printWorkerForm.printType = '1'
      this.loading = true
      console.log(this.printWorkerForm);
      printWorkerCode({ params: this.printWorkerForm }).then(res => {
        let { code, data, msg } = res.data;
        console.log(res);
        if (code == 200) {
          data.roomNo = '工作人员'
          printBarcode(data)
          this.$message({
            message: '打印成功！',
            type: 'success'
          });
          this.checkRecordDialog = false
        } else {
          this.$message.error(msg)
        }
        this.loading = false
      }).catch(()=>{
        this.loading = false
      })
    },
    // 批量勾选
    handleSelection(val) {
      this.checkData = val;
      console.log(this.checkData);
    },
    // 批量操作
    manyLog(){
      console.log(this.checkData);
      this.workerDialog2 = true
      samplingModeList().then(res => {
        if (res.data.code === "200") {
          this.samplingModeList2 = res.data.data
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    // 设置请求参数
    setParamsInfo() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      let { searchValue, isolationPointId } = this.queryForm;
      if (searchValue) params.searchValue = searchValue;
      if (isolationPointId) params.isolationPointId = isolationPointId;

      if (this.pickerTime && this.pickerTime.length == 2) {
        params.startTime = this.pickerTime[0] + " 00:00:00";
        params.endTime = this.pickerTime[1] + " 23:59:59";
      }
      if(this.queryForm.peopleStaute){
        params.status = this.queryForm.peopleStaute
      }
      return params;
    },

    getWorkerRegList() {
      this.loading = true;
      let params = this.setParamsInfo();
      workerRegList({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          let { totalRow, list } = data;
          list.forEach(item => {
            if (item.cardNo) {
              let idNumber = getIdNumberInfo(item.cardNo)
              item.age = idNumber.age + " 岁";
              item.sex = idNumber.sex == 0 ? "男" : "女";
            }
          })
          this.staffCheckList = list;
          console.log(res,'66666666666');
          this.total = totalRow;
        } else {
          this.$message.error(msg);
        }
        this.loading = false;
      })
    },

    // 导出
    exportListData() {
      let params = this.setParamsInfo();
      messageBox(() => {
        exportWorkerRegListApi(params).then(res => {
          importFile(res.data, '工作人员检测数据')
        })
      }, 'warning', '确定需要导出工作人员检测数据吗？')
    },

    queryInfo() {
      this.pageNumber = 1;
      this.getWorkerRegList();
    },

    resetQueryInfo() {
      this.pickerTime = [];
      this.queryForm = {
        searchValue: "",
        isolationPointId: ""
      };
      this.getWorkerRegList();
    },

    closeDialog() {
      if (this.formDialog) this.formDialog = false;
      if (this.checkRecordDialog) this.checkRecordDialog = false;
    },

    // 新增 / 编辑
    addStaffInfo(type, row) {
      this.formInfo = {
        name: "",
        linkPhone: "",
        cardNo: "",
        status:"1",
      };
      this.formDialog = true;
      if (type === "add") {
        this.dialogTitle = "新增工作人员信息";
        this.$refs.diglogRef.openDialog(type);
      } else if (type === "edit") {
        this.dialogTitle = "修改工作人员信息";
        this.getWorkerRegInfo(row.id);
        this.$refs.diglogRef.openDialog(type, row.id);
      }
    },

    // 启动
    handleStart(row){
            this.loading = true
            let mystatus = ''
            if(row.status == '1'){
              mystatus = '0'
            }else if(row.status == '0'){
              mystatus = '1'
            }
            let params = {
              id:row.id,
              status:mystatus
            }
            changeStatus(params).then(res=>{
              console.log(res,'7777777777777');
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '启动成功!'
                });
              }else{
                this.$message.error('启动失败！');
              }
              this.getWorkerRegList()
              this.loading = false
            })
    },
    // 关闭
    handleEnd(row){
      this.loading = true
            let mystatus = ''
            if(row.status == '1'){
              mystatus = '0'
            }else if(row.status == '0'){
              mystatus = '1'
            }
            let params = {
              id:row.id,
              status:mystatus
            }
            changeStatus(params).then(res=>{
              console.log(res,'7777777777777');
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '关闭成功!'
                });
              }else{
                this.$message.error('关闭失败！');
              }
              this.getWorkerRegList()
              this.loading = false
            })
    },
    // 回显数据
    getWorkerRegInfo(id) {
      let params = { id };
      workerRegInfo({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          this.formInfo = data;
        } else {
          this.$message.error(msg)
        }
      })
    },

    // 表单验证
    verifyFormInfo() {
      let { name, linkPhone, cardNo } = this.formInfo;
      if (!name) {
        this.$message.warning("请输入姓名");
        return false;
      }

      if (!cardNo) {
        this.$message.warning("请输入身份证号");
        return false;
      } else {
        if (!isIDCard(cardNo)) {
          this.$message.warning("请输入正确的身份证号");
          return false;
        }
      }

      if (!linkPhone) {
        this.$message.warning("请输入手机号");
        return false;
      } else {
        if (!isPhone(linkPhone)) {
          this.$message.warning("请输入正确的手机号");
          return false;
        }
      }
      return true;
    },

    // 提交表单
    async submitFormInfo(data) {
      if (!this.verifyFormInfo()) return;

      let params = this.formInfo;
      params.orgCode = this.userdoctor.orgCode;
      let result;
      let that =this
      if (data.type === "add") {
        that.loading = true
        result = await saveWorkerReg(params);
      } else if (data.type === "edit") {
        console.log('编辑');
        params.id = data.id;
        that.loading = true
        result = await updateWorkerReg(params);
      }
      let { code, msg } = result.data;
      if (code == 200) {
        this.$message.success(msg);
        this.$refs.diglogRef.closeDialog();
        this.getWorkerRegList();
      }
      console.log(666666);
      that.loading = false
    },

    // 历次检测查看
    checkRecord(id) {
      this.workerRegId = id;
      this.checkRecordDialog = true;
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageData.pageNumber,
        pageSize: this.pageData.pageSize,
        workerRegId: id
      };
      workerCovidList({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          let { totalRow, list } = data;
          this.checkRecordList = list;
          this.pageData.total = totalRow;
        } else {
          this.$message.error(msg)
        }
      })
    },

    // 录入
    enterCheckRecord(row) {
      this.$refs.EnterCheckRecordRef.show(row);
    },
    printWorkerDialog(row) {
      this.printWorkerForm.id = row.id
      this.whetherTodayBarCode= row.whetherTodayBarCode
      this.workerDialog = true
      samplingModeList().then(res => {
        if (res.data.code === "200") {
          this.samplingModeList = res.data.data
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    // 批量打印条码
    printWorker2() {
      let params = {
        ids:this.checkData,
        samplingMode:this.printWorkerForm2.samplingMode
      }
      this.loading = true
      console.log('5555');
      batchPrintWorkerCovidBarCode(params).then(res => {
        let msg = res.data.msg;
        if (res.status == 200) {
          batchPrintBarcode(res.data.data);
          this.workerDialog2 = false
        } else {
          this.$message.error(msg)
        }
        this.getWorkerRegList()
        this.loading = false
      }).catch(()=>{
        this.loading = false
      })
    },
    // 打印条码
    printWorker(type) {
      if(type == '1'){
        // 已生成
        this.printWorkerForm.printType ='1'
      }else if(type == '0'){
        // 重新生成
        this.printWorkerForm.printType ='2'
      }
      this.loading = true
      console.log(this.printWorkerForm);
      printWorkerCode({ params: this.printWorkerForm }).then(res => {
        let { code, data, msg } = res.data;
        console.log(res);
        if (code == 200) {
          data.roomNo = '工作人员'
          printBarcode(data)
          this.workerDialog = false
        } else {
          this.$message.error(msg)
        }
        this.getWorkerRegList()
        this.loading = false
      }).catch(()=>{
        this.loading = false
      })
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getWorkerRegList();
    },

    handleCurrentChange(val) {
      this.pageNumber = val;
      this.getWorkerRegList();
    },

    changeSize(val) {
      this.pageData.pageSize = val;
      this.checkRecord(this.workerRegId);
    },

    changeCurrent(val) {
      this.pageData.pageNumber = val;
      this.checkRecord(this.workerRegId);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.form-width {
  width: 280px;
}
</style>

<style scoped>
.el-table>>>td {
  padding: 6px 0 !important;
}

.el-link {
  margin-right: 10px;
}

.content_page{
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.statusBtn{
  cursor: auto;
}
.statusBtn2{
  cursor: auto;
}
.statusBtn:hover {
  background: rgb(64,158,255);
}
.statusBtn2:hover {
  background: rgb(245,108,108);
}
</style>
